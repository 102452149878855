@import url("https://fonts.googleapis.com/css2?family=Questrial&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kode+Mono&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Almendra&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Almendra+Display&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Almendra+SC&display=swap");
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

/* width */
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey; 
  border-radius: 1px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 1px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000; 
}

.bg-future {
  background: linear-gradient(
    to right,
    purple 0%,
    red 33%,
    red 66%,
    purple 100%
  );
}

.bg-parchment {
  font-weight:700;
  background-image: url("./assets/img/parchment.jpg");
  background-size: repeat;
  filter: brightness(1.25);
}

.bg-stone {
  background-image: url("./assets/img/slatestone.png");
  background-size: cover;
}

.bg-radial-parchment {
  background: radial-gradient(
    ellipse at center,
    #faf9b8 25%,
    transparent 75%
  );
}

.text-shadow-big {
  text-shadow: 1px 1px 10px #1e293b;
}

.text-shadow-red {
  text-shadow: 1px 1px 3px red;
}

.shadow-world {
  box-shadow: 0 0 25px 5px white;
  outline: 1px solid black;
  filter: drop-shadow(0 0 10px white);
}

.shadow-world-black {
  box-shadow: 0 0 20px 1px black inset;
  filter: drop-shadow(0 0 5px black);
}

.border-b-future {
  border-bottom: 2px;
  border-style: solid;
  border-image: linear-gradient(
      to right,
      red 0%,
      purple 33%,
      purple 66%,
      red 100%
    )
    1;
}

.border-r-future {
  border-right: 2px;
  border-style: solid;
  border-image: linear-gradient(
      to bottom,
      red 0%,
      purple 33%,
      purple 66%,
      red 100%
    )
    1;
}

.border-l-future {
  border-left: 2px;
  border-style: solid;
  border-image: linear-gradient(
      to bottom,
      red 0%,
      purple 33%,
      purple 66%,
      red 100%
    )
    1;
}

.border-t-future {
  border-top: 2px;
  border-style: solid;
  border-image: linear-gradient(
      to right,
      red 0%,
      purple 33%,
      purple 66%,
      red 100%
    )
    1;
}

.border-future {
  border-image: linear-gradient(
      to right,
      red 0%,
      purple 33%,
      purple 66%,
      red 100%
    )
    1;
  border-style: solid;
  border-width: 1px;
}

.border-stone {
  position: relative;
  padding: 6px;
  border-radius: 11px;  
  box-shadow: inset 3px -5px 3px 2px black, inset -3px 5px 5px 3px rgba(0, 0, 0, 0.5), -1px 1px 2px 1px rgba(0, 0, 0, 0.5);
  width: calc(100%);
}

.border-stone:before{
  content: "";
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  border-radius: 11px;
  border-width: 12px;
  border-image-source: url("./assets/img/border-stone-2.png");
  border-image-slice: 22 22 22 22;
  border-image-repeat: round;
  border-image-outset: 1px;
}

.shadowy {
  box-shadow: 0 0 0 1px black;
}

.fill-stone {
  height: calc(100% + 24px);
  width: calc(100% + 24px);
  box-shadow: 0 0 0 1px black;
  border-radius: 8px;
  margin-top: -12px;
  margin-left: -12px;
}

@keyframes slide {
  0% {
    transform: translateY(-500px);
  }
  100% {
    transform: translateY(0px);
  }
}

.slide-in {
  animation-name: slide;
  animation-duration: 0.25s;
  animation-iteration-count: 1;
}

@keyframes chain {
  0% {
    transform: translateY(-500px);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.chain-in {
  animation-name: chain;
  animation-duration: 3s;
  animation-iteration-count: 1;
}

.chain-in-fast {
  animation-name: chain;
  animation-duration: 1s;
  animation-iteration-count: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-iteration-count: 1;  
  animation-fill-mode: forwards;
}

.fade-in-slow {
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.fade-in-superslow {
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}